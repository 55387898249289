import React from "react";

const RzaBHeaderBody = ({ heading, children }) => (
  <>
    <h2 className="text-2xl mb-6 md:mb-8">{heading}</h2>
    <div className="mt-6 opacity-60 md:mt-8">{children}</div>
  </>
);

export default RzaBHeaderBody;
