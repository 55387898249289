import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const RzaBImageSection = ({ imageSrc }) => (
  <span className="h-64 w-screen sm:h-96 md:h-[30rem]">
    <GatsbyImage
      alt="Rosie image"
      image={imageSrc}
      className="w-full h-64 sm:h-96 md:h-[30rem]"
    />
  </span>
);

export default RzaBImageSection;
