import React, { useRef } from "react";
import { useInView } from "framer-motion";
import RzaBHeaderBody from "../atoms/RzaBHeaderBody";
import * as styles from "./rzabContentNextToElement.module.css";

const RzaBContentNextToElement = ({ heading, element, children }) => {
  const firstSection = useRef();
  const isInView = useInView(firstSection, { once: true, margin: "-20px" });

  return (
    <div
      className="w-screen max-w-screen flex items-center justify-center left-1/2 -ml-1/2"
      ref={firstSection}
      style={{
        transform: isInView ? "none" : "translateY(200px) translateX(200px)",
        opacity: isInView ? 1 : 0,
        transition: "all 0.6s cubic-bezier(0.17, 0.55, 0.55, 1) 0s",
      }}
    >
      <div className="w-full max-w-full">
        <div
          className={`text-left flex flex-col-reverse flex-nowrap items-center py-9 px-5 md:flex-row sm:py-11 md:py-20 md:px-16`}
        >
          <div className="w-full min-h-full mt-20 min-h-full md:pr-36">
            <RzaBHeaderBody heading={heading}>{children}</RzaBHeaderBody>
          </div>
          <div
            className={`w-full min-h-full -my-9 -mx-5 sm:-my-11 md:-my-20 md:-mx-16 ${styles.overOneHundredWidth}`}
          >
            {element}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RzaBContentNextToElement;
