import React from "react";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesDown } from "@fortawesome/free-solid-svg-icons";
import RzaBImageSection from "../atoms/RzaBImageSection";
import RzaBElementNextToContent from "../molecules/RzaBElementNextToContent";
import RzaBContentNextToElement from "../molecules/RzaBContentNextToElement";
import ReactPlayer from "react-player";
import RzaBSocials from "../atoms/RzaBSocials";

const RosalieBea = ({ bgImgSrc, sections }) => {
  const heroImage = getImage(bgImgSrc);

  const fadedHeroImage = [
    heroImage,
    `linear-gradient(to top, rgba(25,25,25,0.929), rgba(25,25,25,0.929))`,
  ].reverse();

  return (
    <div className="bg-zinc-800 overflow-hidden text-yellow-600">
      <BgImage
        image={fadedHeroImage}
        className="w-screen max-w-screen flex min-h-screen items-center justify-center"
        style={{
          left: "50vw",
          marginLeft: "-50vw",
          backgroundPosition: "0% 0%, center",
          backgroundRepeat: "repeat, repeat",
          backgroundSize: "auto, cover",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="w-full max-w-full md:max-w-5xl">
          <div className="py-10 px-6 text-left sm:py-11 sm:px-7 md:py-24 md:px-16">
            <h1 className="text-3xl text-white mb-4 sm:mb-5 sm:text-4xl md:mb-6 lg:text-5xl 2xl:text-6xl 2xl:mb-7">
              Rosie Braddy
            </h1>
            <p className="text-sm text-opacity-70 my-4 sm:my-5 md:my-6 lg:text-lg 2xl:text-xl 2xl:my-7">
              Rosalie Bea
            </p>
            <FontAwesomeIcon
              icon={faAnglesDown}
              className="text-xl text-opacity-70 sm:text-2xl lg:text-3xl 2xl:text-4xl"
            />
          </div>
        </div>
      </BgImage>
      <RzaBElementNextToContent
        element={
          <ReactPlayer
            url="https://soundcloud.com/rosemarybraddymezzo/sets/functions-parties-and-events"
            width="100%"
            height="30rem"
          />
        }
        heading="Nottinghamshire's most versatile singer"
      >
        From weddings, to funerals or functions keep reading to find out how
        Rosie Braddy can add a musical twist to your event.
      </RzaBElementNextToContent>
      {sections.map((s, idx) => {
        const ThisSectionComponent =
          idx % 2 === 0 ? RzaBContentNextToElement : RzaBElementNextToContent;

        return (
          <ThisSectionComponent
            key={idx}
            element={<RzaBImageSection imageSrc={getImage(s.image)} />}
            heading={s.heading}
          >
            {s.content.content}
          </ThisSectionComponent>
        );
      })}
      <div className="px-7 py-14 bg-zinc-700 border-x-0 border-b-0 border-t-4 border-yellow-600">
        <div className="flex justify-center">
          <RzaBSocials />
        </div>
      </div>
    </div>
  );
};

export default RosalieBea;
