import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/utils/seo";
import { motion } from "framer-motion";
import RosalieBea from "../components/pages/rosaliebea";

const RosalieBeaPage = ({ data }) => {
  return (
    <>
      <Seo metaProperties={data.allContentfulSeoProperties.nodes[0]} />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <RosalieBea
          bgImgSrc={data.allContentfulAsset.nodes[0]}
          sections={
            data.allContentfulWebsiteContentSections.nodes[0].contentSections
          }
        />
      </motion.div>
    </>
  );
};

export default RosalieBeaPage;

export const pageQuery = graphql`
  query {
    allContentfulAsset(
      filter: { contentful_id: { eq: "1hAiAEDeNqR1uE1poCCMbG" } }
    ) {
      nodes {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    allContentfulSeoProperties {
      nodes {
        description {
          description
        }
        image {
          gatsbyImageData(layout: FIXED)
        }
        title
      }
    }
    allContentfulWebsiteContentSections(
      filter: { contentful_id: { eq: "56Yrf3BrR3MZ1RYm7oWJMX" } }
    ) {
      nodes {
        contentSections {
          heading
          content {
            content
          }
          image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
