import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import {
  faFacebook,
  faInstagram,
  faSoundcloud,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

function iconStyles() {
  return "text-2xl";
}

function SocialLink({ icon, link }) {
  return (
    <OutboundLink target="_blank" href={link} rel="noreferrer">
      <FontAwesomeIcon className={iconStyles()} icon={icon} />
    </OutboundLink>
  );
}

function RzaBSocials() {
  return (
    <div className="w-full flex justify-between px-8 mt-8 sm:max-w-lg lg:mt-16">
      <SocialLink
        className={iconStyles()}
        icon={faFacebook}
        link="https://www.facebook.com/CeremonySingerRosieBraddy"
      />
      <SocialLink
        className={iconStyles()}
        icon={faInstagram}
        link="https://www.instagram.com/ceremonyandweddingsingerrb/"
      />
      <SocialLink
        className={iconStyles()}
        icon={faSoundcloud}
        link="https://soundcloud.com/rosemarybraddymezzo/sets/weddings-and-funerals"
      />
      <SocialLink
        className={iconStyles()}
        icon={faTwitter}
        link="https://twitter.com/RBraddyMezzo"
      />
      <SocialLink
        className={iconStyles()}
        icon={faYoutube}
        link="https://www.youtube.com/channel/UCeBbLTqnhnkk8TzQN9ltA7w"
      />
    </div>
  );
}

export default RzaBSocials;
